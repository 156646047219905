import { useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { differenceInDays } from 'date-fns';
import gql from 'graphql-tag';

import {
	ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useIsEstablishedUserTraitName } from '@confluence/onboarding-helpers/entry-points/hooks/useIsEstablishedUserTraitName';
import { useUserByLicensedProducts } from '@confluence/onboarding-helpers/entry-points/hooks/useUserByLicensedProducts';
import { fg } from '@confluence/feature-gating';
import { useSessionData, useIsAdmin } from '@confluence/session-data';

import { HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS } from '../homeOnboardingTakeoverV2OnboardingStateKeys';

const HTV2PersonalizationQuery = gql`
	query useAdminHomeOnboardingTakeoverV2EligibilityUserPersonalizationQuery($userId: String!)
	@experimental {
		experimentalUserPersonalization(userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;

/**
 * Custom hook to determine the eligibility for the Home Onboarding Takeover V2.
 *
 * This hook checks multiple conditions to determine if a user is eligible for the onboarding experience.
 * The conditions include:
 * 1. Either:
 *   a. The user has already been exposed to this takeover OR,
 *   b. They are an new user/not an established user.
 * 2. Whether the user is a site admin and a founding admin.
 * 3. Whether the user is a land user.
 *
 * @returns {boolean} - Returns `true` if the user passes all eligibility checks, otherwise `false`.
 */
export const useAdminHomeOnboardingTakeoverV2Eligibility = () => {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { userId } = useSessionData();
	const isEstablishedUserTraitName = useIsEstablishedUserTraitName();

	useEffect(() => {
		experienceTracker.start({
			name: ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2,
		});
	}, [experienceTracker]);

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[
			ONBOARDING_EXPERIENCES.IS_FOUNDING_ADMIN,
			...Object.values(HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS),
		],
		!isSiteAdmin || !fg('cc_home_onboarding_takeover_v2_eligibility'),
	);
	const { isFoundingAdmin, takeoverSeenAt, hasDismissedTakeover } =
		deserializeState(onboardingStateData);
	const hasAdminAccess = useIsAdmin();
	const isSiteAndFoundingAdmin = isSiteAdmin && isFoundingAdmin && hasAdminAccess;

	const isInOnboardingPeriod =
		Boolean(takeoverSeenAt) && differenceInDays(new Date(), new Date(takeoverSeenAt)) <= 7;
	const takeoverExpired =
		Boolean(takeoverSeenAt) && differenceInDays(new Date(), new Date(takeoverSeenAt)) > 7;

	const {
		data: userPersonalizationQueryData,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
	} = useQuery(HTV2PersonalizationQuery, {
		skip: !isSiteAndFoundingAdmin || takeoverExpired || hasDismissedTakeover,
		variables: {
			userId: userId!,
		},
	});

	const inEligibleForTakeover = !hasAdminAccess || takeoverExpired || hasDismissedTakeover;
	const attributes =
		userPersonalizationQueryData?.experimentalUserPersonalization?.attributes ?? [];
	// if we skip querying for personalization,
	// we can assume that user should be establishedUser and skip over onboarding
	const isEstablishedUser =
		inEligibleForTakeover ||
		attributes.find(
			(attr: { name: string; value: string }) => attr?.name === isEstablishedUserTraitName,
		)?.value === 'true';

	const isEstablishedUserReady = !userPersonalizationQueryLoading && !userPersonalizationQueryError;

	const isNotEstablishedUser = isEstablishedUserReady && !isEstablishedUser;

	const {
		isLandUser,
		isXflowUser,
		error: userTypeError,
		loading: userTypeLoading,
	} = useUserByLicensedProducts({
		skip:
			!isSiteAdmin ||
			!isSiteAndFoundingAdmin ||
			takeoverExpired ||
			hasDismissedTakeover ||
			!fg('cc_home_onboarding_takeover_v2_eligibility'),
	});

	/**
	 * 1. If they have seen it, then they are simply eligible for the takeover.
	 * 2. If they haven't seen it yet,  then we need to check the other eligibility conditions
	 * 		to determine if we should set `takeoverSeenAt` later -> show the takeover.
	 */
	const isEligible =
		takeoverSeenAt !== undefined ||
		Boolean(
			isNotEstablishedUser &&
				isSiteAndFoundingAdmin &&
				fg('cc_home_onboarding_takeover_v2_eligibility'),
		);

	if (userTypeError || onboardingStateError || userPersonalizationQueryError) {
		experienceTracker.stopOnError({
			name: ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2,
			error: (userTypeError || onboardingStateError || userPersonalizationQueryError)!,
		});
	}

	return {
		isEligibleForLand: isLandUser && isEligible,
		isEligibleForXflow: isXflowUser && isEligible,
		isVisible:
			(isLandUser || isXflowUser) &&
			isEligible &&
			(takeoverSeenAt === undefined || isInOnboardingPeriod) &&
			!hasDismissedTakeover,
		loading: onboardingStateLoading || userTypeLoading || userPersonalizationQueryLoading,
	};
};
