import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { useSessionData } from '@confluence/session-data';
import { getAGGClient } from '@confluence/graphql';

import type { PersonalizedTemplatesV2GetPersonalSpaceKeyQuery } from '../__types__/PersonalizedTemplatesV2GetPersonalSpaceKeyQuery';
import type {
	PersonalizedTemplatesV2GetSubanchorsAGGQuery,
	PersonalizedTemplatesV2GetSubanchorsAGGQueryVariables,
} from '../__types__/PersonalizedTemplatesV2GetSubanchorsAGGQuery';

const SubAnchorQuery = gql`
	query useGetTeamAndSubanchorGetSubanchorsAGGQuery($accountId: ID!) {
		growthUnifiedProfile_getUnifiedProfile(accountId: $accountId) {
			paidChannelContext {
				confluence {
					subAnchor
				}
			}
		}
	}
`;

const GetPersonalSpaceKeyAndTeamQuery = gql`
	query useGetTeamAndSubanchorGetPersonalSpaceKeyQuery {
		user(current: true) {
			id
			team
			confluence {
				hasPersonalSpace
				space {
					id
					key
				}
			}
		}
	}
`;

export const useGetTeamAndSubanchor = ({ skip = true }: { skip: boolean }) => {
	const { userId } = useSessionData();
	const {
		data: subAnchorData,
		loading: subAnchorLoading,
		error: subAnchorError,
	} = useQuery<
		PersonalizedTemplatesV2GetSubanchorsAGGQuery,
		PersonalizedTemplatesV2GetSubanchorsAGGQueryVariables
	>(SubAnchorQuery, {
		variables: { accountId: userId! },
		client: getAGGClient(),
		skip,
	});

	const {
		data: personalSpaceKeyAndTeamData,
		loading: personalSpaceKeyAndTeamLoading,
		error: personalSpaceKeyAndTeamError,
	} = useQuery<PersonalizedTemplatesV2GetPersonalSpaceKeyQuery>(GetPersonalSpaceKeyAndTeamQuery, {
		skip,
	});

	const personalSpaceKey =
		(personalSpaceKeyAndTeamData?.user?.confluence?.hasPersonalSpace &&
			personalSpaceKeyAndTeamData?.user?.confluence?.space?.key) ||
		'';
	const teamType = personalSpaceKeyAndTeamData?.user?.team || '';
	const subAnchor =
		subAnchorData?.growthUnifiedProfile_getUnifiedProfile?.paidChannelContext?.confluence
			?.subAnchor || '';

	return {
		teamType,
		personalSpaceKey,
		subAnchor,
		personalSpaceKeyAndTeamLoading,
		personalSpaceKeyAndTeamError,
		subAnchorLoading,
		subAnchorError,
	};
};
