import { useContext, useEffect } from 'react';

import {
	ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useOnboardingNewHomeTakeoverEnabled } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingNewHomeTakeover';
import { expValEquals } from '@confluence/feature-experiments';

import { useAdminHomeOnboardingTakeoverV2Eligibility } from './useAdminHomeOnboardingTakeoverV2Eligibility';
import { useNthUserHomeOnboardingTakeoverV2Eligibility } from './useNthUserHomeOnboardingTakeoverV2Eligibility';
import { useIsEligibleForPersonalizedTemplatesV2 } from '@confluence/experiment-personalized-templates-v2/entry-points/useIsEligibleForPersonalizedTemplatesV2';
export type OnboardingTakeoverType =
	| 'v1'
	| 'v2-land'
	| 'v2-xflow'
	| 'nth-user'
	| 'personalized-templates-v2';

/**
 * Hook to determine which onboarding takeover will be displayed.
 *
 * @returns The type of onboarding takeover, or `null` if no takeover.
 */
export function useOnboardingTakeoverType(): OnboardingTakeoverType | null {
	const { isEnabled: isV1Enabled, loading: isV1Loading } = useOnboardingNewHomeTakeoverEnabled();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const {
		isEligibleForLand: v2EligibleLand,
		isEligibleForXflow: v2EligibleXflow,
		isVisible: v2Visible, // Is not visible after 7 days
		loading: v2Loading,
	} = useAdminHomeOnboardingTakeoverV2Eligibility();

	const {
		isEligible: v2NthUserEligible,
		loading: v2NthUserLoading,
		isVisible: v2NthUserVisible,
	} = useNthUserHomeOnboardingTakeoverV2Eligibility();

	const {
		isEligibleForLand: ptv2Eligible,
		loading: ptv2Loading,
		isVisible: isPTV2Visible,
	} = useIsEligibleForPersonalizedTemplatesV2();

	const defaultLandFoundingAdminExperience = !isV1Loading && isV1Enabled;

	useEffect(() => {
		const v2Eligible = v2EligibleLand || v2EligibleXflow;
		if (!v2Loading && !v2Eligible) {
			experienceTracker.abort({
				name: ONBOARDING_HOME_ONBOARDING_TAKEOVER_V2,
				reason: 'Not eligible for v2 takeover',
			});
		}
	}, [v2EligibleLand, v2EligibleXflow, v2Loading, experienceTracker]);

	if (v2Loading || isV1Loading || ptv2Loading) {
		return null;
	}

	ptv2Eligible &&
		expValEquals('cc-onboarding-personalized-templates-v2-aa', 'cohort', 'experiment');

	if (
		ptv2Eligible &&
		expValEquals('cc-onboarding-personalized-templates-v2', 'cohort', 'experiment')
	) {
		return isPTV2Visible ? 'personalized-templates-v2' : null;
	} else if (
		v2EligibleXflow &&
		expValEquals('cc_home_onboarding_takeover_v2_xflow', 'cohort', 'experiment')
	) {
		return v2Visible ? 'v2-xflow' : null;
	} else if (defaultLandFoundingAdminExperience) {
		return 'v1';
	}

	if (
		!v2NthUserLoading &&
		v2NthUserEligible &&
		expValEquals('cc_onboarding_nth_user_home_takeover', 'cohort', 'experiment')
	) {
		return v2NthUserVisible ? 'nth-user' : null;
	}

	return null;
}
