import { useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { addDays, isAfter } from 'date-fns';

import {
	ONBOARDING_NTH_USER_HOME_ONBOARDING_TAKEOVER,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useSessionData, AccessStatus } from '@confluence/session-data';
import { isLocaleEnglish } from '@confluence/onboarding-helpers/entry-points/isLocaleEnglish';
import { useIsEstablishedUserTraitName } from '@confluence/onboarding-helpers/entry-points/hooks/useIsEstablishedUserTraitName';
import { fg } from '@confluence/feature-gating';

import { HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS } from '../homeOnboardingTakeoverV2OnboardingStateKeys';

const HOME_ONBOARDING_TAKEOVER_NTH_USER_DAYS = 14;

const HTV2PersonalizationQuery = gql`
	query useNthUserHomeOnboardingTakeoverV2EligibilityUserPersonalizationQuery($userId: String!)
	@experimental {
		experimentalUserPersonalization(userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;

/**
 * Custom hook to determine the eligibility for the Nth user Home Onboarding Takeover.
 *
 * This hook checks multiple conditions to determine if a user is eligible for the onboarding experience.
 * The conditions include:
 * 1. Whether the user joins a paid instance
 * 2. Whether the user is a nth user(not a founding admin).
 * 3. Whether the user's locale is english
 * 4. Whether the user is new(not an established user)
 * 5. Whether the user has feature gate enabled
 *
 * @returns {boolean} - Returns `true` if the user passes all eligibility checks, otherwise `false`.
 */
export const useNthUserHomeOnboardingTakeoverV2Eligibility = () => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { accessStatus, userId, edition, locale } = useSessionData();
	const isEstablishedUserTraitName = useIsEstablishedUserTraitName();

	// User is licensed (site admin or nth user)
	const hasAdminOrUseAccess =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	// User joins a paid editions and has locale as english
	const isEligibleForExperiment = edition !== ConfluenceEdition.FREE && isLocaleEnglish(locale);

	useEffect(() => {
		experienceTracker.start({
			name: ONBOARDING_NTH_USER_HOME_ONBOARDING_TAKEOVER,
		});
	}, [experienceTracker]);

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[
			ONBOARDING_EXPERIENCES.IS_FOUNDING_ADMIN,
			...Object.values(HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS),
		],
		!hasAdminOrUseAccess ||
			!isEligibleForExperiment ||
			!fg('cc_onboarding_nth_user_home_takeover_gate'),
	);
	const { isFoundingAdmin, takeoverSeenAt } = deserializeState(onboardingStateData);

	const takeoverExpired =
		Boolean(takeoverSeenAt) &&
		isAfter(Date.now(), addDays(new Date(takeoverSeenAt), HOME_ONBOARDING_TAKEOVER_NTH_USER_DAYS));

	const {
		data: userPersonalizationQueryData,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
	} = useQuery(HTV2PersonalizationQuery, {
		skip:
			!hasAdminOrUseAccess ||
			!isEligibleForExperiment ||
			takeoverExpired ||
			!fg('cc_onboarding_nth_user_home_takeover_gate'),
		variables: {
			userId: userId!,
		},
	});
	const attributes =
		userPersonalizationQueryData?.experimentalUserPersonalization?.attributes ?? [];
	const isEstablishedUser =
		attributes.find(
			(attr: { name: string; value: string }) => attr?.name === isEstablishedUserTraitName,
		)?.value === 'true';

	const isEstablishedUserReady = !userPersonalizationQueryLoading && !userPersonalizationQueryError;

	const isNotEstablishedUser = isEstablishedUserReady && !isEstablishedUser;

	const isEligible = Boolean(
		hasAdminOrUseAccess &&
			isEligibleForExperiment &&
			isNotEstablishedUser &&
			!isFoundingAdmin &&
			fg('cc_onboarding_nth_user_home_takeover_gate'),
	);

	if (onboardingStateError || userPersonalizationQueryError) {
		experienceTracker.stopOnError({
			name: ONBOARDING_NTH_USER_HOME_ONBOARDING_TAKEOVER,
			error: (onboardingStateError || userPersonalizationQueryError)!,
		});
	}

	return {
		isEligible,
		isVisible: isEligible && !takeoverExpired,
		loading: onboardingStateLoading || userPersonalizationQueryLoading,
	};
};
